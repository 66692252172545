import React from 'react'
import cx from 'classnames'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import styles from './FulfillmentDisplay.module.css'
import { getFormattedDiningOption } from '../FulfillmentSelectorModal/fulfillment-helpers'
import { getFormattedAddressShort } from '../../utils/address-helpers'
import { FutureEstimate } from '../FutureEstimate/FutureEstimate'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import { getBrandedText } from '@local/translations'

interface Props {
  editable: boolean
  showDeliveryAddress?: boolean
  showPickupAddress?: boolean
  inline?: boolean
  stylizedForLink?: boolean
}

export const FulfillmentDisplay: React.FC<Props> = ({
  editable,
  showDeliveryAddress = false,
  showPickupAddress = false,
  inline = false,
  stylizedForLink = false
}) => {
  const { orderingAvailable } = useAvailability()
  const { diningOptionBehavior, selected } = useFulfillment()
  const {
    error,
    loading,
    applicableConfigs: { brandingConfig }
  } = useRestaurant()
  const formattedDiningOption = getFormattedDiningOption(diningOptionBehavior)

  if (loading || error) return null

  const color = stylizedForLink && editable ? 'text-primary-75' : 'text-default'
  const emphasis = `type-default font-bold ${color} group-hover:underline`

  if (!orderingAvailable) {
    return (
      <div className={cx(styles.fulfillmentDisplay, color, 'px-4')}>
        {getBrandedText('not-accepting-orders', brandingConfig)}
      </div>
    )
  }

  return (
    <div
      className={cx(
        'flex w-full type-default text-left group',
        inline && 'justify-center px-4'
      )}
    >
      <div
        className={cx(
          styles.fulfillmentRows,
          inline && styles.inline,
          stylizedForLink ? 'text-default' : color
        )}
      >
        {!selected ? (
          <span className={emphasis}>Select a date and time</span>
        ) : (
          <>
            <span>
              {formattedDiningOption}
              &nbsp;
              <FutureEstimate textClass={emphasis} />
              &nbsp;
            </span>
            <LocationDisplay
              showDeliveryAddress={showDeliveryAddress}
              showPickupAddress={showPickupAddress}
              emphasis={emphasis}
              inline={inline}
            />
          </>
        )}
      </div>
      {editable && (
        <EditIcon
          aria-label='Edit fulfillment'
          className={cx(
            'ml-4 self-center',
            color,
            stylizedForLink && 'hover:text-primary-100'
          )}
        />
      )}
    </div>
  )
}

const LocationDisplay = ({
  showDeliveryAddress,
  showPickupAddress,
  emphasis,
  inline
}: {
  showDeliveryAddress: boolean
  showPickupAddress: boolean
  emphasis: string
  inline: boolean
}) => {
  const { diningOptionBehavior, deliveryInfo, customLocationName } =
    useFulfillment()
  const { restaurantInfo } = useRestaurant()

  return (
    <>
      {(customLocationName || (showDeliveryAddress && deliveryInfo)) &&
        diningOptionBehavior === 'DELIVERY' && (
          <span className={cx(inline && styles.inlineAddress)}>
            to&nbsp;
            <span className={emphasis}>
              {customLocationName || getFormattedAddressShort(deliveryInfo!!)}
            </span>
          </span>
        )}
      {(customLocationName || showPickupAddress) &&
        diningOptionBehavior === 'TAKE_OUT' &&
        restaurantInfo?.address && (
          <span className={cx(inline && styles.inlineAddress)}>
            from&nbsp;
            <span className={emphasis}>
              {customLocationName ||
                getFormattedAddressShort(restaurantInfo.address)}
            </span>
          </span>
        )}
    </>
  )
}
