import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useGoogleAdsConversionTags, useMetaAdsConversionTags } from './ads'

const AllPagesTag: React.FC = () => {
  const { data: googleData } = useGoogleAdsConversionTags()
  const { data: metaData } = useMetaAdsConversionTags()

  return (
    <>
      {/* COO Discover page tracking */}
      <Helmet>
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=AW-16843855258'
        />
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16843855258');`}
        </script>
      </Helmet>

      {googleData?.conversionTagId && (
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleData.conversionTagId}`}
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
    
            gtag('config', '${googleData.conversionTagId}');`}
          </script>
        </Helmet>
      )}
      {metaData?.pixelId && (
        <Helmet>
          <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('dataProcessingOptions', ['LDU']);
            fbq('init', '{${metaData.pixelId}}');
            fbq('track', 'PageView');`}
          </script>
        </Helmet>
      )}
    </>
  )
}

export default AllPagesTag
