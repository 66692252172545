import React, { ReactNode } from 'react'
import { useDiningOptions } from '../../hooks/useDiningOptions'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { AvailabilityEntry } from '../../types/fulfillment'
import {
  formatDOWs,
  formatIntervals,
  renderNodes
} from '../../utils/availability-util'
import isEqual from 'lodash/isEqual'
import { DiningOptionBehavior } from '../../types/cart'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'

const pickupMsg = 'Available for pickup orders'
const deliveryMsg = 'Available for delivery orders'
const deliveryPartialMsg = ' and for delivery orders'
const bothMsg = 'Available for pickup and delivery orders'

const getFormattedDisplayAvailability = (
  displayAvailability: AvailabilityEntry | undefined,
  timeZoneId?: string
): ReactNode | undefined => {
  if (!displayAvailability) {
    return undefined
  }

  return renderNodes([
    ...formatDOWs(displayAvailability?.displayDaysOfWeek),
    ...formatIntervals(
      displayAvailability?.displayDates ?? [],
      timeZoneId ?? undefined
    )
  ])
}

const getFormattedCurrentAvailability = (
  displayAvailability: AvailabilityEntry | undefined,
  timeZoneId?: string
): ReactNode | undefined => {
  if (!displayAvailability) {
    return undefined
  }

  return renderNodes([
    ...formatIntervals(
      displayAvailability?.availableDates ?? [],
      timeZoneId ?? undefined
    )
  ])
}

export const SpecialMenuFulfillmentDisplay = ({
  testId,
  behavior
}: {
  testId: string
  behavior: DiningOptionBehavior
}) => {
  const { displayName } = useDiningOptions()

  return (
    <>
      {displayName && (
        <div
          className={'bg-gray-0 text-center p-4'}
          data-testid={`specialMenuFulfillmentDisplay-${testId}`}
        >
          <div className={'type-headline-5 text-default font-semibold'}>
            {displayName}
          </div>
          <div>
            <SpecialMenuAvailability
              className={'text-secondary mt-2'}
              behavior={behavior}
            />
          </div>
        </div>
      )}
    </>
  )
}

const isBehaviorNotAvailable = (
  isFullyAvailable: boolean,
  availability: AvailabilityEntry | undefined
) => {
  return (
    !isFullyAvailable &&
    availability &&
    !availability?.availableDaysOfWeek.length &&
    !availability?.availableDates.length
  )
}

const getFormattedAvailabilities = (
  displayBehaviorsTogether: boolean,
  displayBoth: boolean,
  formattedTakeoutDisplay: ReactNode | undefined,
  formattedDeliveryDisplay: ReactNode | undefined
) => {
  let displayAvailabilities: { prefix: string; node: ReactNode }[] = []
  // both available and for the same days
  if (displayBehaviorsTogether) {
    displayAvailabilities = [
      {
        prefix: bothMsg,
        node: formattedDeliveryDisplay
      }
    ]
    // both available but for different days
  } else if (displayBoth) {
    displayAvailabilities = [
      {
        prefix: pickupMsg,
        node: formattedTakeoutDisplay
      },
      {
        prefix: deliveryPartialMsg,
        node: formattedDeliveryDisplay
      }
    ]
    // only takeout available
  } else if (formattedTakeoutDisplay) {
    displayAvailabilities = [
      {
        prefix: pickupMsg,
        node: formattedTakeoutDisplay
      }
    ]
    // only delivery available
  } else if (formattedDeliveryDisplay) {
    displayAvailabilities = [
      {
        prefix: deliveryMsg,
        node: formattedDeliveryDisplay
      }
    ]
  }

  return displayAvailabilities
}

const getCurrentDatesToDisplay = (
  isFullyAvailable: boolean,
  formattedCurrent: ReactNode | undefined
) => {
  return isFullyAvailable ? undefined : formattedCurrent
}

export const SpecialMenuAvailability = ({
  className,
  behavior,
  showCurrentAvailability = false
}: {
  className: string
  behavior: DiningOptionBehavior | undefined
  showCurrentAvailability?: boolean
}) => {
  const { specialMenuAvailability, displayName } = useDiningOptions()
  const { restaurantInfo } = useRestaurant()

  if (!(displayName && specialMenuAvailability)) {
    return null
  }

  const takeout = specialMenuAvailability['TAKE_OUT']
  const formattedTakeoutDisplay = getFormattedDisplayAvailability(
    takeout,
    restaurantInfo?.timeZoneId ?? undefined
  )

  const delivery = specialMenuAvailability['DELIVERY']
  const formattedDeliveryDisplay = getFormattedDisplayAvailability(
    delivery,
    restaurantInfo?.timeZoneId ?? undefined
  )

  const displayBoth = Boolean(takeout && delivery)
  const displayBehaviorsTogether =
    displayBoth && isEqual(formattedTakeoutDisplay, formattedDeliveryDisplay)

  const displayAvailabilities = getFormattedAvailabilities(
    displayBehaviorsTogether,
    displayBoth,
    formattedTakeoutDisplay,
    formattedDeliveryDisplay
  )

  if (displayAvailabilities.length === 0) {
    return <UnavailableState className={className} displayName={displayName} />
  }

  return (
    <div className={className}>
      <p
        className={
          showCurrentAvailability
            ? 'font-semibold text-default w-full text-center'
            : ''
        }
      >
        <Availabilities
          keyPrefix={'display-'}
          availabilities={displayAvailabilities}
        />
      </p>

      {showCurrentAvailability && behavior && (
        <CurrentAvailability
          behavior={behavior}
          takeout={takeout}
          delivery={delivery}
          displayName={displayName}
          restaurantInfo={restaurantInfo}
        />
      )}
    </div>
  )
}

const CurrentAvailability = ({
  behavior,
  takeout,
  delivery,
  restaurantInfo,
  displayName
}: {
  behavior: DiningOptionBehavior
  takeout: AvailabilityEntry | undefined
  delivery: AvailabilityEntry | undefined
  restaurantInfo: { timeZoneId?: string } | undefined
  displayName: string
}) => {
  const availability = behavior == 'TAKE_OUT' ? takeout : delivery

  const formattedCurrent = getFormattedCurrentAvailability(
    availability,
    restaurantInfo?.timeZoneId ?? undefined
  )

  const isFullyAvailable =
    isEqual(
      availability?.availableDaysOfWeek,
      availability?.displayDaysOfWeek
    ) && isEqual(availability?.availableDates, availability?.displayDates)

  const currentDatesToDisplay = getCurrentDatesToDisplay(
    isFullyAvailable,
    formattedCurrent
  )

  const behaviorNotAvailable = isBehaviorNotAvailable(
    isFullyAvailable,
    availability
  )

  const currentDaysAheadToDisplay = availability?.displayOrderAheadDays

  const hasDaysOfWeek =
    availability && availability?.availableDaysOfWeek.length > 0

  const behaviorStr = behavior == 'TAKE_OUT' ? 'Pickup' : 'Delivery'

  return (
    <>
      {(currentDatesToDisplay || behaviorNotAvailable || hasDaysOfWeek) && (
        <>
          <DividingLine noMargin className={'my-2'} />
          <p className={'text-secondary text-center'}>
            <p>
              <>
                {currentDatesToDisplay && (
                  <>
                    Currently accepting {behaviorStr.toLowerCase()} for{' '}
                    {currentDatesToDisplay}.
                    <br />
                  </>
                )}
                {behaviorNotAvailable && (
                  <>
                    Not currently accepting {behaviorStr.toLowerCase()} orders
                    for {displayName}.
                    <br />
                  </>
                )}
                {behaviorStr} orders open {currentDaysAheadToDisplay} days
                ahead. Check back soon for more dates!
              </>
            </p>
          </p>
        </>
      )}
    </>
  )
}

const Availabilities = ({
  availabilities,
  keyPrefix
}: {
  keyPrefix: string
  availabilities: { prefix: string; node: ReactNode }[]
}) => {
  return (
    <>
      {availabilities.map(({ prefix, node }) => (
        <span key={`${keyPrefix}${prefix}`}>
          {prefix}
          {' on '}
          {node}
        </span>
      ))}
      .
    </>
  )
}

const UnavailableState = ({
  className,
  displayName
}: {
  className: string
  displayName: string
}) => {
  return (
    <p
      className={className}
    >{`Currently not accepting orders for ${displayName}. Have questions? Contact us!`}</p>
  )
}
