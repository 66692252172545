export const Locales = [
  'en-AU',
  'en-CA',
  'en-IE',
  'en-NZ',
  'en-GB',
  'en-US',
  'es-US',
  'af-NA',
  'ak-GH',
  'sq-AL',
  'sq-XK',
  'sq-MK',
  'am',
  'am-ET',
  'ar-DZ',
  'ar',
  'ar-BH',
  'ar-TD',
  'ar-KM',
  'ar-DJ',
  'ar-EG',
  'ar-IL',
  'ar-JO',
  'ar-KE',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MR',
  'ar-MA',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-SO',
  'ar-TN',
  'ar-AE',
  'ar-EH',
  'ar-YE',
  'hy-AM',
  'az',
  'bm',
  'be',
  'bn',
  'bn-IN',
  'bs',
  'bg',
  'my',
  'ca-AD',
  'ca',
  'ca-ES',
  'ca-FR',
  'ca-IT',
  'zh-Hans',
  'zh-HK',
  'zh-MO',
  'zh-SG',
  'zh-TW',
  'hr-BA',
  'hr',
  'cs-CZ',
  'da-DK',
  'da-FO',
  'da-GL',
  'dv-MV',
  'nl-AW',
  'nl-BE',
  'nl-BQ',
  'nl-CW',
  'nl-SX',
  'nl-SR',
  'nl',
  'dz',
  'en-AS',
  'en-AI',
  'en-AG',
  'en-AT',
  'en-BS',
  'en-BB',
  'en-BE',
  'en-BZ',
  'en-BM',
  'en-BO',
  'en-BW',
  'en-IO',
  'en-VG',
  'en-BI',
  'en-CM',
  'en-KY',
  'en-CX',
  'en-CC',
  'en-CK',
  'en-CY',
  'en-DK',
  'en-DM',
  'en-SZ',
  'en-FK',
  'en-FJ',
  'en-FI',
  'en-GM',
  'en-DE',
  'en-GH',
  'en-GI',
  'en-GL',
  'en-GD',
  'en-GU',
  'en-GG',
  'en-GY',
  'en-HK',
  'en-IN',
  'en-IM',
  'en-IL',
  'en-JM',
  'en-JE',
  'en-KE',
  'en-KI',
  'en-LS',
  'en-LR',
  'en-MO',
  'en-MG',
  'en-MW',
  'en-MY',
  'en-MT',
  'en-MH',
  'en-MU',
  'en-FM',
  'en-MS',
  'en-NA',
  'en-NR',
  'en-NL',
  'en-NG',
  'en-NU',
  'en-NF',
  'en-MP',
  'en-PK',
  'en-PW',
  'en-PG',
  'en-PH',
  'en-PN',
  'en-PR',
  'en-RW',
  'en-SH',
  'en-KN',
  'en-LC',
  'en-VC',
  'en-WS',
  'en-SC',
  'en-SL',
  'en-SG',
  'en-SX',
  'en-SI',
  'en-SB',
  'en-ZA',
  'en-SE',
  'en-CH',
  'en-TK',
  'en-TO',
  'en-TT',
  'en-TC',
  'en-TV',
  'en-UG',
  'en-TZ',
  'en-UM',
  'en-VI',
  'en-VU',
  'en-ZM',
  'en-ZW',
  'et-EE',
  'et',
  'ee',
  'ee-TG',
  'fi',
  'fr-DZ',
  'fr-BE',
  'fr-BJ',
  'fr-BF',
  'fr-BI',
  'fr-CM',
  'fr-CA',
  'fr-CF',
  'fr-TD',
  'fr-KM',
  'fr-CG',
  'fr-CI',
  'fr-CD',
  'fr-DJ',
  'fr-GQ',
  'fr',
  'fr-GF',
  'fr-PF',
  'fr-GA',
  'fr-GP',
  'fr-GN',
  'fr-HT',
  'fr-LU',
  'fr-MG',
  'fr-ML',
  'fr-MQ',
  'fr-MR',
  'fr-MU',
  'fr-YT',
  'fr-MC',
  'fr-MA',
  'fr-NC',
  'fr-NE',
  'fr-RW',
  'fr-RE',
  'fr-BL',
  'fr-MF',
  'fr-PM',
  'fr-SN',
  'fr-SC',
  'fr-CH',
  'fr-TG',
  'fr-TN',
  'fr-VU',
  'fr-WF',
  'ff-CM',
  'ff-GN',
  'ff-MR',
  'ka-GE',
  'de-AT',
  'de-BE',
  'de',
  'de-IT',
  'de-LI',
  'de-LU',
  'de-CH',
  'el-CY',
  'el-GR',
  'gu',
  'ha-GH',
  'ha-NE',
  'he-IL',
  'hu',
  'is',
  'ig-NG',
  'id',
  'ga',
  'it',
  'it-SM',
  'it-CH',
  'ja-JP',
  'kk-KZ',
  'km',
  'rw',
  'rn-BI',
  'ko-KP',
  'ko-KR',
  'ky',
  'lo-LA',
  'lv',
  'lv-LV',
  'ln-AO',
  'ln-CF',
  'ln-CG',
  'lt',
  'lt-LT',
  'lu',
  'lb',
  'mk',
  'mg',
  'ms-BN',
  'ms',
  'ms-SG',
  'mt',
  'mn',
  'ne-IN',
  'ne-NP',
  'ne',
  'nb-NO',
  'nn-NO',
  'nb-SJ',
  'pa',
  'ps-AF',
  'fa-TJ',
  'pl',
  'pt-AO',
  'pt-BR',
  'pt-CV',
  'pt-GQ',
  'pt-GW',
  'pt-LU',
  'pt-MO',
  'pt-MZ',
  'pt',
  'pt-ST',
  'pt-CH',
  'pt-TL',
  'qu-BO',
  'qu-EC',
  'ro-MD',
  'ro',
  'rm-CH',
  'ru-BY',
  'ru-KZ',
  'ru-KG',
  'ru-MD',
  'ru',
  'ru-UA',
  'sg',
  'sr-BA',
  'sr-XK',
  'sr-ME',
  'sr-RS',
  'sn',
  'si',
  'sk',
  'sl',
  'so-DJ',
  'so-ET',
  'so-KE',
  'so',
  'es-AR',
  'es-BO',
  'es-BR',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-CU',
  'es-DO',
  'es-EC',
  'es-SV',
  'es-GQ',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PY',
  'es-PE',
  'es-PH',
  'es-PR',
  'es',
  'es-UY',
  'es-VE',
  'sw-CD',
  'sw-FI',
  'sw-KE',
  'sw-SE',
  'sw-UG',
  'sv-FI',
  'sv-SE',
  'sv-AX',
  'ta-MY',
  'ta-SG',
  'ta-LK',
  'te-IN',
  'th',
  'ti-ER',
  'tr-CY',
  'tr',
  'tk',
  'tk-TM',
  'uk-UA',
  'ur-IN',
  'uz',
  'vi-VN',
  'cy',
  'yo-BJ',
  'zu-ZA'
] as const

//List of locales used in storybook and dev tools dropdowns
export const DropdownLocales = [
  'en-AU',
  'en-CA',
  'en-IN',
  'en-IE',
  'en-NZ',
  'en-GB',
  'en-US',
  'es-US',
  'zh-Hans'
] as const

export type Locale = (typeof Locales)[number]
