import { DaysOfWeek, ScheduleInterval } from '../types/config'
import { toShortDateString } from './time-utils'
import React, { ReactNode } from 'react'
import { FutureAvailability, Interval } from '../types/menu'
import capitalize from 'lodash/capitalize'

export const renderDatesDescription = (
  futureAvailabilities: FutureAvailability[],
  timeZoneId?: string
): ReactNode => {
  return renderNodes([
    getDaysOfWeekNode(futureAvailabilities),
    getNoStartDateNode(futureAvailabilities, timeZoneId),
    getDateRangesNode(futureAvailabilities, timeZoneId),
    getNoEndDateNode(futureAvailabilities, timeZoneId)
  ])
}

export const formatIntervals = (
  intervals: ScheduleInterval[],
  timeZoneId: string | undefined
) => {
  return intervals.map((availability) => {
    const startDate = toShortDateString(availability.start!!, timeZoneId)
    const endDate = toShortDateString(availability.end!!, timeZoneId)
    return `${startDate}${startDate !== endDate ? ` - ${endDate}` : ''}`
  })
}

export const renderNodes = (nodes: React.ReactNode[]) => {
  const textNodes = nodes.filter((dateStr) => dateStr)

  return getListedNodes(textNodes)
}

export const formatDOWs = (daysOfWeek: DaysOfWeek[] | undefined) => {
  return daysOfWeek?.map((dow) => capitalize(dow.toLowerCase() + 's')) || []
}

export const getIntervalsDescription = (intervals: Interval[]) => {
  const descriptions = intervals.map((interval) =>
    getIntervalDescriptions(interval)
  )

  return getListedStrings(descriptions)
}

const getIntervalDescriptions = (interval: Interval) => {
  if (interval.start == interval.end) {
    return `at ${interval.start}`
  } else {
    return `${interval.start} - ${interval.end}`
  }
}

const getListedStrings = (array: string[]) => {
  if (array.length === 0) return ''
  if (array.length === 2) {
    return array.join(' and ')
  }
  return array.reduce(
    (text, value, i) => text + (i < array.length - 1 ? ', ' : ', and ') + value
  )
}

const getDateRangesNode = (
  futureAvailabilities: FutureAvailability[],
  timeZoneId?: string
): ReactNode => {
  const intervals = futureAvailabilities
    .filter((availability) => availability.start && availability.end)
    .map((availability) => {
      return {
        start: availability.start!!,
        end: availability.end!!
      }
    })

  return formatIntervals(intervals, timeZoneId)
}

const getNoEndDateNode = (
  futureAvailabilities: FutureAvailability[],
  timeZoneId?: string
): ReactNode => {
  const startingDate = futureAvailabilities.find(
    (availability) => availability.start && !availability.end
  )?.start

  return startingDate ? (
    <>
      {' starting '}
      <span className='font-semibold'>
        {toShortDateString(startingDate, timeZoneId)}
      </span>
    </>
  ) : undefined
}

const getDaysOfWeekNode = (futureAvailabilities: FutureAvailability[]) => {
  const dowAvailability = futureAvailabilities.find(
    (availability) => availability.daysOfWeek.length > 0
  )
  return formatDOWs(dowAvailability?.daysOfWeek)
}

const getNoStartDateNode = (
  futureAvailabilities: FutureAvailability[],
  timeZoneId?: string
): ReactNode => {
  const untilDate = futureAvailabilities.find(
    (availability) => !availability.start && availability.end
  )?.end

  return untilDate ? (
    <>
      {' through '}
      <span className='font-semibold'>
        {toShortDateString(untilDate, timeZoneId)}
      </span>
    </>
  ) : undefined
}

const getListedNodes = (nodes: ReactNode[]): ReactNode | undefined => {
  if (nodes.length === 0) return undefined
  if (nodes.length === 1) return nodes[0]
  return (
    <>
      {nodes.map((node, i) => {
        if (i === nodes.length - 1) {
          return (
            <>
              {' and '}
              {node}
            </>
          )
        }
        if (nodes.length > 2) {
          return (
            <>
              {node}
              {', '}
            </>
          )
        }
        return node
      })}
    </>
  )
}
