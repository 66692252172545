import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Order } from '../../client/types/orders'
import { useGoogleAdsConversionTags, useMetaAdsConversionTags } from './ads'

interface ConfirmationTagProps {
  completedOrder: Order
}

const ConfirmationTag: React.FC<ConfirmationTagProps> = ({
  completedOrder
}) => {
  const { data: googleData } = useGoogleAdsConversionTags()
  const { data: metaData } = useMetaAdsConversionTags()

  const tax = completedOrder.checks[0].taxAmount
  const discount = completedOrder.checks[0].totalDiscountAmount
  const total = completedOrder.checks[0].totalAmount + discount - tax

  return (
    <>
      {/* COO Discover page tracking */}
      <Helmet>
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=AW-16843855258'
        />
        <script>
          {`gtag('event', 'conversion', {
             'send_to': 'AW-16843855258/WEADCP-IsZkaEJqr498-',
             'value': ${total},
             'tax': ${tax},
             'currency': 'USD',
             'transaction_id': '${completedOrder.guid}'
            });
          `}
        </script>
      </Helmet>

      {googleData?.conversionTagId && (
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleData.conversionTagId}`}
          ></script>
          <script>
            {`gtag('event', 'conversion', {
             'send_to': '${googleData.conversionTagId}/${googleData.eventLabel}',
             'value': ${total},
             'tax': ${tax},
             'currency': 'USD',
             'transaction_id': '${completedOrder.guid}'
            });
          `}
          </script>
        </Helmet>
      )}
      {metaData?.pixelId && (
        <Helmet>
          <script>
            {`fbq(
            'track', 'Purchase', {
              source_type: 'catering',
              action_source: 'website',
              partner_agent: 'Toast',
              value: ${total},
              currency: 'USD'
            }
           );`}
          </script>
        </Helmet>
      )}
    </>
  )
}

export default ConfirmationTag
