import React from 'react'
import { SpecialMenuAvailability } from '../../FulfillmentDisplay/SpecialMenuFulfillmentDisplay'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { DiningOptionBehavior } from '../../../types/cart'
import { t, getBrandedText } from '@local/translations'

export const FulfillmentHeader: React.FC<{
  editing: boolean
  displayName: string | undefined
  behavior: DiningOptionBehavior | undefined
}> = ({ editing, displayName, behavior }) => {
  const {
    applicableConfigs: { brandingConfig }
  } = useRestaurant()

  const headerTitle = editing
    ? getBrandedText('update-order', brandingConfig)
    : getBrandedText('start-order', brandingConfig)

  return (
    <>
      <h2
        data-testid='scheduled-modal-header'
        className='pb-2 font-semibold type-large'
      >
        {displayName || headerTitle}
      </h2>
      {displayName ? (
        <SpecialMenuAvailability
          className='pb-4 font-normal type-subhead'
          behavior={behavior}
          showCurrentAvailability
        />
      ) : (
        <p className='pb-4 font-normal type-subhead'>
          {t('fulfillment.modal.header-description')}
        </p>
      )}
    </>
  )
}
