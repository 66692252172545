import React from 'react'
import { Badge } from '@toasttab/buffet-pui-badge'
import { useAvailability } from '@local/do-secundo-availability-provider'

export const OrderingAvailabilty = () => {
  const { orderingAvailable } = useAvailability()

  if (!orderingAvailable) {
    return <Badge color={Badge.Color.warning}>Ordering Unavailable</Badge>
  }

  return <Badge color={Badge.Color.info}>Ordering Available</Badge>
}
