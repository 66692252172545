import * as React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { AvailabilityProvider } from '@local/do-secundo-availability-provider'
import { CartProvider } from '@local/do-secundo-cart-provider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useRestaurantStorage } from '../../utils/restaurant-storage'
import { CartPage } from '../CartPage/CartPage'
import { CheckoutPage } from '../CheckoutPage/CheckoutPage'
import { ConfirmPage } from '../ConfirmPage/ConfirmPage'
import { FulfillmentProvider } from '../FulfillmentProvider/FulfillmentProvider'
import { Header } from '../Header/Header'
import MenuPage from '../MenuPage/MenuPage'
import { ModeRouter } from '../ModeRouter/ModeRouter'
import { RumRoute } from '../RumRoute'
import { Spotlight } from '../RestaurantHeader/Spotlight/Spotlight'
import { useEffect, useRef, useState } from 'react'
import { renderStyleMeta } from '@toasttab/sites-components'
import { getImageUrl } from '../RestaurantHeader/RestaurantLogo'
import { brandingPublicAssetURL } from '../../api/util'
import { InstagramWarning } from './InstagramWarning'
import { PaymentContextProvider } from '../../account/PaymentContext'
import { useTracker } from '../../analytics/tracker'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useSessionRefresh } from '../Session/useSessionRefresh'
import { getBrandedText } from '@local/translations'

export const RestaurantPage = () => {
  const {
    restaurantGuid,
    setBasePath,
    applicableConfigs: { brandingConfig, spotlightConfig },
    updateApplicableConfigsRequest
  } = useRestaurant()

  const { fastLinkName: fastLinkNameEncoded } = useParams<{
    fastLinkName: string | undefined
  }>()
  const { utm_source } = useQueryParams()
  const fastLinkName = fastLinkNameEncoded
    ? decodeURIComponent(fastLinkNameEncoded)
    : undefined

  const tracker = useTracker()

  useSessionRefresh()

  useEffect(() => {
    if (restaurantGuid) {
      tracker.trackRestaurantPageUtmSource(utm_source, restaurantGuid)
    }
  }, [restaurantGuid])

  useEffect(() => {
    if (fastLinkName) {
      setBasePath(`/menu/${encodeURIComponent(fastLinkName)}/`)
      updateApplicableConfigsRequest({ name: fastLinkName })
    } else {
      setBasePath('/')
      updateApplicableConfigsRequest({ name: undefined })
    }
  }, [fastLinkName])

  useEffect(() => {
    if (brandingConfig?.favicon) {
      let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = getImageUrl(brandingPublicAssetURL, brandingConfig.favicon)!!
    }
  }, [brandingConfig])

  const [showSpotlightModal, setShowSpotlightModal] = useState(false)
  useEffect(() => {
    if (spotlightConfig?.modalOpenByDefault) {
      setShowSpotlightModal(true)
    }
  }, [spotlightConfig?.modalOpenByDefault])

  const spotlightComponent = (
    <Spotlight
      config={spotlightConfig}
      showModal={showSpotlightModal}
      setShowModal={setShowSpotlightModal}
    />
  )

  const hasUpdatedDocumentTitle = useRef(false)

  useEffect(() => {
    // In the unlikely case where this branding prop changes during
    // the session, don't continue to append to the document title.
    if (brandingConfig?.removeCateringName !== undefined) {
      const titleAppend = getBrandedText('document-title', brandingConfig)
      document.title += ` - ${titleAppend}`
      hasUpdatedDocumentTitle.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandingConfig?.removeCateringName])

  const restaurantStorage = useRestaurantStorage()
  const addItemRoute = `/items/add/:itemGuid/:itemGroupGuid`

  return (
    <AvailabilityProvider fastLinkName={fastLinkName}>
      <CartProvider
        restaurantGuid={restaurantGuid}
        restaurantStorage={restaurantStorage}
        fastLinkName={fastLinkName}
      >
        <>
          <Helmet>
            {renderStyleMeta({ ...brandingConfig }, brandingPublicAssetURL)}
          </Helmet>
          <FulfillmentProvider fastLinkName={fastLinkName}>
            <PaymentContextProvider>
              <Routes>
                <Route path={'/'} element={spotlightComponent} />
                <Route path={addItemRoute} element={spotlightComponent} />
              </Routes>
              <Header />
              <InstagramWarning />
              <ModeRouter />
              <Routes>
                <Route
                  path={`cart`}
                  element={
                    <RumRoute>
                      <CartPage />
                    </RumRoute>
                  }
                />
                <Route
                  path={`checkout`}
                  element={
                    <RumRoute>
                      <CheckoutPage />
                    </RumRoute>
                  }
                />
                <Route
                  path={`confirm/:orderGuid`}
                  element={
                    <RumRoute>
                      <ConfirmPage />
                    </RumRoute>
                  }
                />
                <Route
                  path={'/'}
                  element={
                    <RumRoute>
                      <MenuPage />
                    </RumRoute>
                  }
                />
                <Route
                  path={addItemRoute}
                  element={
                    <RumRoute>
                      <MenuPage />
                    </RumRoute>
                  }
                />
                <Route path={'*'} element={<Navigate to='/' replace />} />
              </Routes>
            </PaymentContextProvider>
          </FulfillmentProvider>
        </>
      </CartProvider>
    </AvailabilityProvider>
  )
}
