import { Fieldset } from '@local/do-secundo-fieldset'
import { CartTable } from '../Cart/CartTable/CartTable'
import * as Tip from './Tip/Tip'
import { CartFooter } from '../Cart/CartFooter/CartFooter'
import { CartValidation } from '../Cart/Validation/CartValidation'
import React from 'react'
import { Cart } from '../../types/cart'
import { CheckoutFormValues } from './utils'
import { Customer } from './CustomerInfo/CustomerInfo'
import { t } from '@local/translations'

export type Props = {
  values: CheckoutFormValues
  cart?: Cart
  customer: Customer
  showTip: boolean
}

export const CartCheckoutFields = ({
  cart,
  values,
  customer,
  showTip
}: Props) => {
  const tipAmount = showTip ? Tip.getArgsForSubmit({ values }).tipAmount : 0

  const label = t('checkout.cart-label', {
    numItems: cart?.order.checks[0].selections.length
  })

  return (
    <>
      {cart ? (
        <Fieldset label={label} collapsable collapsed={false}>
          <CartTable cart={cart} />
        </Fieldset>
      ) : null}
      {showTip && <Tip.Component />}
      {cart ? (
        <CartFooter
          cart={cart}
          customer={customer}
          showTotal
          showTip={showTip}
          tip={tipAmount}
          isCheckoutForm={true}
          paymentType={values.paymentType}
        />
      ) : null}
      {cart ? <CartValidation cart={cart} /> : null}
    </>
  )
}
