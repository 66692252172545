import { BrandingConfig } from '../../../client/types/config'
import { AppStrings, t } from './utilities'

type OrderTypeKey = 'catering-order' | 'order'

type BrandedTextKey =
  | 'your-order'
  | 'start-order'
  | 'update-order'
  | 'not-accepting-orders'
  | 'confirm-title'
  | 'document-title'

/**
 * This function gets any string from the translations file (en-US.json)
 * that are dependent on a prop in the branding config. These string 
 * accessors all start with "branded-text".
 * 
 * @param key: string corresponding to to the second accessor following
 * the parent "branded-text"
 */
export const getBrandedText = (
  key: BrandedTextKey,
  brandingConfig?: BrandingConfig
) => {
  // There are a few places where strings are dependent on whether we're
  // referring to "catering orders" or just "orders".
  const subKey: OrderTypeKey = brandingConfig?.removeCateringName
    ? 'order'
    : 'catering-order'

  const fullKey: keyof AppStrings = `branded-text.${key}.${subKey}`
  return t(fullKey)
}
