import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Cart } from '../Cart/Cart'
import { CartButton } from '@local/do-secundo-cart-button'
import { Dollars } from '@local/do-secundo-dollars'
import { useGetCart } from '../CartQuery/CartQuery'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useRestoreScrollPosition } from '@toasttab/do-secundo-use-restore-scroll-position'
import { MenuPageError } from './MenuPage.error'
import { MenuPageLayout } from './MenuPageLayout/MenuPageLayout'
import { MenuPageLoader } from './MenuPage.loader'
import style from './MenuPage.module.css'
import { useHasMode } from '../ModeRouter/ModeRouter'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import cx from 'classnames'
import { MenuPageContextProvider, useMenuPageContext } from './MenuPageContext'
import { FulfillmentRedirect } from '../FulfillmentSelector/FulfillmentRedirect'
import { MenuNav } from '../Menus/MenuNav/MenuNav'
import { RestaurantDetails } from '../RestaurantHeader/RestaurantDetails/RestaurantDetails'
import { SpecialMenuFulfillmentDisplay } from '../FulfillmentDisplay/SpecialMenuFulfillmentDisplay'
import { NoMenus } from '../Menus/NoMenus'
import { MenuHeader } from '../Menus/MenuHeader'
import { MenuGroups } from '../Menus/MenuGroups/MenuGroups'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { FulfillmentSelector } from '../FulfillmentSelector/FulfillmentSelector'
import { useMenuNav } from '../Menus/MenuNav/util'
import { AddItem } from '../Modifiers/AddItem'
import { RecentOrders } from './RecentOrders/RecentOrders'

const MenuPage = () => {
  useRestoreScrollPosition()

  const { restaurantInfo, getRestaurantPath } = useRestaurant()
  const { orderingAvailable, loading: availabilityLoading } = useAvailability()
  const { selected: fulfillmentSelected, diningOptionBehavior } =
    useFulfillment()
  const { menus, menusError, menusLoading, menusRetry } = useMenuPageContext()

  const {
    selectedMenuIndex,
    setSelectedMenuIndex,
    scrolledMenuIndex,
    setScrolledMenuIndex,
    selectedGroupGuid,
    setSelectedGroupGuid,
    scrolledGroupGuid,
    setScrolledGroupGuid,
    menuRefs
  } = useMenuNav(menus)

  const {
    error: cartError,
    loading: cartLoading,
    numberOfSelections,
    cartTotal
  } = useGetCart()
  const isCartMode = useHasMode('cart')

  const showCartButton =
    !cartError && !cartLoading && !isCartMode && numberOfSelections > 0

  if (menusLoading || availabilityLoading) {
    return <MenuPageLoader />
  }

  return (
    <>
      <FulfillmentRedirect />
      <AddItem onCloseLink={getRestaurantPath()} />
      <MenuPageLayout
        header={
          <>
            <div className={style.fulfillment}>
              <div className={style.fulfillmentDisplay}>
                <FulfillmentSelector />
              </div>
            </div>
            <RestaurantDetails />
            <SpecialMenuFulfillmentDisplay
              testId={'rx-header'}
              behavior={diningOptionBehavior}
            />
            {menuRefs.length > 0 && (
              <div className={cx(style.stickyMenu)}>
                <MenuNav
                  menus={menuRefs}
                  selectedGroupGuid={selectedGroupGuid}
                  setSelectedGroupGuid={setSelectedGroupGuid}
                  selectedMenuIndex={selectedMenuIndex}
                  setSelectedMenuIndex={setSelectedMenuIndex}
                />
              </div>
            )}
          </>
        }
        menus={
          menusError || (!menus && !menusLoading) ? (
            <MenuPageError retry={menusRetry} restaurantInfo={restaurantInfo} />
          ) : (
            <>
              {menuRefs.length === 0 ? (
                <NoMenus />
              ) : (
                <>
                  <RecentOrders />
                  {menuRefs.map((menu, index) => {
                    const showHeading =
                      menuRefs.length > 1 ||
                      !!menu.description ||
                      !!menu.futureAvailability
                    return (
                      <div key={menu.name}>
                        {showHeading && (
                          <MenuHeader
                            name={menu.name}
                            futureAvailability={menu.futureAvailability}
                            description={menu.description}
                            ref={menu.ref}
                          />
                        )}
                        <MenuGroups
                          menuGroups={menu.groups}
                          menuName={menu.name}
                          menuDisabled={!menu.enabled}
                          orderingAvailable={orderingAvailable}
                          fulfillmentSelected={fulfillmentSelected}
                          onMenuVisible={(groupGuid) => {
                            if (scrolledGroupGuid === selectedGroupGuid) {
                              setSelectedGroupGuid(groupGuid)
                            }
                            if (selectedMenuIndex === scrolledMenuIndex) {
                              setSelectedMenuIndex(index)
                              setSelectedGroupGuid(groupGuid)
                            }
                            setScrolledGroupGuid(groupGuid)
                            setScrolledMenuIndex(index)
                          }}
                        />
                        {index !== menuRefs.length - 1 && (
                          <hr className='h-px p-0 my-6 border-0 bg-coo-primary-text' />
                        )}
                      </div>
                    )
                  })}
                </>
              )}
              <div className={cx(style.footer, 'w-full text-center')}>
                <PoweredByToast className={cx(style.poweredBy)} />
              </div>
            </>
          )
        }
        cartBody={
          <Cart
            orderingAvailable={orderingAvailable}
            checkoutLink={getRestaurantPath('checkout')}
          />
        }
      />
      <div className={cx(style.cartButton)}>
        {showCartButton && (
          <CartButton
            to={getRestaurantPath('cart')}
            left={<span>View cart ({numberOfSelections})</span>}
            right={<Dollars amount={cartTotal} />}
            showModificationErrors
            loading={false}
          />
        )}
      </div>
      <ToastContainer
        hideProgressBar
        closeButton={false}
        className='toastContainer'
        toastClassName={style.toastWrapper}
      />
    </>
  )
}

// eslint-disable-next-line import/no-default-export
export default () => {
  return (
    <MenuPageContextProvider>
      <MenuPage />
    </MenuPageContextProvider>
  )
}
