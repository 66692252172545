import { useFormikContext } from 'formik'
import { ModalBody, ModalFooter } from '@toasttab/buffet-pui-modal'
import { FulfillmentHeader } from './FulfillmentHeader/FulfillmentHeader'
import { DiningOptionSelector } from './DiningOptionSelector/DiningOptionSelector'
import { LocationPicker } from './LocationPicker/LocationPicker'
import { FulfillmentTimeSelector } from './FulfillmentTimeSelector/FulfillmentTimeSelector'
import { FulfillmentFooter } from './FulfillmentFooter/FulfillmentFooter'
import React from 'react'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { DiningOptionFulfillmentData } from '../../hooks/useDiningOptions'
import { FulfillmentFormValues } from '../../types/fulfillment'
import { useDeliveryAddressValidator } from './fulfillment-helpers'
import { Progress } from '@local/do-secundo-progress'

export type FulfillmentModalContentProps = {
  data: DiningOptionFulfillmentData[]
  displayName?: string
}

export const FulfillmentModalContent = ({
  data,
  displayName
}: FulfillmentModalContentProps) => {
  const formik = useFormikContext<FulfillmentFormValues>()
  const { values, setValues } = formik
  const { deliveryInfo, diningOptionBehavior, customLocationName } = values

  const { selected } = useFulfillment()
  const hasFulfillmentDates = data.length > 0

  const {
    valid: isDeliveryDistanceValid,
    loading: isValidating,
    data: deliveryValidationResult
  } = useDeliveryAddressValidator(
    deliveryInfo,
    diningOptionBehavior,
    customLocationName
  )

  return (
    <>
      <ModalBody data-testid='FulfillmentSelectorModal'>
        <FulfillmentHeader
          editing={selected}
          displayName={displayName}
          behavior={values.diningOptionBehavior}
        />
        <DiningOptionSelector
          values={values}
          data={data}
          setValues={setValues}
        />
        {!displayName && <LocationPicker fulfillmentValues={values} />}
        {isValidating ? (
          <div className={'text-center -mt-12 mb-6'}>
            <Progress />
            <div className={'-mt-12'}>Validating delivery address...</div>
          </div>
        ) : (
          <FulfillmentTimeSelector
            data={data}
            formik={formik}
            deliveryValidationResult={deliveryValidationResult}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <FulfillmentFooter
          formik={formik}
          editing={selected}
          noAvailableDates={!hasFulfillmentDates}
          isDeliveryDistanceValid={isDeliveryDistanceValid}
          isDeliveryValidationLoading={isValidating}
        />
      </ModalFooter>
    </>
  )
}
